<template>
  <div class="publicacoes">
    <Voltar />

    <h1 class="titulo">Publicações</h1>

    <div v-for="publicacao in state.publicacoes" :key="publicacao.id"
        :class="'home__conteudo--item home__conteudo--item-publicacoes' + (!user.assinatura ? ' exlusivo-assinante ' : '')"
    >
      <span>
        <svg viewBox="0 0 84.03 84.03">
          <title>exclama</title>
          <g id="Camada_2" data-name="Camada 2">
            <g id="Camada_1-2" data-name="Camada 1">
              <path
                class="cls-1"
                d="M42,0A42,42,0,1,0,84,42,42,42,0,0,0,42,0Zm0,77.76A35.74,35.74,0,1,1,77.76,42,35.74,35.74,0,0,1,42,77.76Z"
              />
              <path
                class="cls-1"
                d="M34.1,64.27a7.69,7.69,0,0,1,8-8.05c4.69,0,7.79,3.36,7.87,8.05s-3.1,8.05-7.87,8.05A7.69,7.69,0,0,1,34.1,64.27ZM37,51.79,35,11.71H49.05L47.1,51.79Z"
              />
            </g>
          </g>
        </svg>
        <span>Rede America Notícias</span>
      </span>

      <iframe v-if="publicacao.video && user.assinatura" class="video-home" :src="'https://player.vimeo.com/video/' + publicacao.video" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      <img v-if="!publicacao.video || !user.assinatura" class="video-home" :src="publicacao.imagem"
      :style="!user.assinatura ? 'opacity: 0.2;' : ''"
      >
      <p>
        {{ publicacao.titulo }}
      </p>
      <a v-if="user.assinatura" @click="publicacao.show = true" style="text-align: left;">Leia mais...</a>
      <p v-if="publicacao.show" v-html="publicacao.descricao" style="text-align: left;"></p>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import services from '../../services'
import Voltar from '../../components/Voltar'
export default {
  setup () {
    const user = JSON.parse(window.localStorage.getItem('user'))
    const router = useRouter()
    const toast = useToast()

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      publicacoes: {}
    })

    async function getPublicacoes () {
      try {
        state.isLoading = true
        const { data, errors } = await services.publicacoes.index()

        if (!errors) {
          state.publicacoes = data
          console.log(state.publicacoes)
          state.isLoading = false
          return
        }

        if (errors.status === 404) {
          toast.error('Ainda não há publicacoes')
        }

        if (errors.status === 401) {
          toast.error('Ainda não há publicacoes')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar os publicacoes!')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getPublicacoes()

    return {
      router,
      state,
      user
    }
  },
  components: { Voltar }
}
</script>
